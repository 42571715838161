export const IMAGE_VIJAY = require('../images/vijay_soni.jpg')

export const FB = require('../images/facebook.png')
export const INSTA = require('../images/instagram.png')
export const WHATSAPP = require('../images/whatsapp.png')
export const TWITTER = require('../images/twitter.png')
export const LINKEDIN = require('../images/linkedin.png')
export const WEB_DEV = require('../images/web.png')
export const APP_DEV = require('../images/app_development.png')
export const DEV = require('../images/development.png')
export const SKILLS_BG = require('../images/skillsbg.jpg')